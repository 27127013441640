/*All CSS can be added here*/


@font-face {
    font-family: "Overpass-Black";
    src: local("Overpass-Black"),
    url("fonts/Overpass-Black.ttf") format("truetype");
}

@font-face {
    font-family: "FLTicker-Regular";
    src: local("FlTicker-Regular"),
    url("fonts/FLTicker-Regular-SVG.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-BlackItalic";*/
/*    src: local("Overpass-BlackItalic"),*/
/*    url("fonts/Overpass-BlackItalic.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-Bold";
    src: local("Overpass-Bold"),
    url("fonts/Overpass-Bold.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-BoldItalic";*/
/*    src: local("Overpass-BoldItalic"),*/
/*    url("fonts/Overpass-BoldItalic.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*    font-family: "Overpass-BoldItalic";*/
/*    src: local("Overpass-BoldItalic"),*/
/*    url("fonts/Overpass-ExtraBold.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*    font-family: "Overpass-ExtraBoldItalic";*/
/*    src: local("Overpass-ExtraBoldItalic"),*/
/*    url("fonts/Overpass-ExtraBoldItalic.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-ExtraLight";
    src: local("Overpass-ExtraLight"),
    url("fonts/Overpass-ExtraLight.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-ExtraLightItalic";*/
/*    src: local("Overpass-ExtraLightItalic"),*/
/*    url("fonts/Overpass-ExtraLightItalic.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*    font-family: "Overpass-BoldItalic";*/
/*    src: local("Overpass-BoldItalic"),*/
/*    url("fonts/Overpass-Italic.ttf") format("truetype");*/
/*}*/


/*@font-face {*/
/*    font-family: "Overpass-Italic-VariableFont_wght";*/
/*    src: local("Overpass-Italic-VariableFont_wght"),*/
/*    url("fonts/Overpass-Italic-VariableFont_wght.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-Light";
    src: local("Overpass-Light"),
    url("fonts/Overpass-Light.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-LightItalic";*/
/*    src: local("Overpass-LightItalic"),*/
/*    url("fonts/Overpass-LightItalic.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-Medium";
    src: local("Overpass-Medium"),
    url("fonts/Overpass-Medium.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-MediumItalic";*/
/*    src: local("Overpass-MediumItalic"),*/
/*    url("fonts/Overpass-MediumItalic.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-Regular";
    src: local("Overpass-Regular"),
    url("fonts/Overpass-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Overpass-SemiBold";
    src: local("Overpass-SemiBold"),
    url("fonts/Overpass-SemiBold.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-SemiBoldItalic";*/
/*    src: local("Overpass-SemiBoldItalic"),*/
/*    url("fonts/Overpass-SemiBoldItalic.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-Thin";
    src: local("Overpass-Thin"),
    url("fonts/Overpass-Thin.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: "Overpass-ThinItalic";*/
/*    src: local("Overpass-ThinItalic"),*/
/*    url("fonts/Overpass-ThinItalic.ttf") format("truetype");*/
/*}*/

@font-face {
    font-family: "Overpass-VariableFont_wght";
    src: local("Overpass-VariableFont_wght"),
    url("fonts/Overpass-VariableFont_wght.ttf") format("truetype");
}


.seven {
    /*background-image: url('assets/images/farelanes-splash.jpg');*/
    /*background-size: cover !* contain *! !* width in px/em/rem ie 50rem 20rem *!*/
}

.close-button {
    font-size: 18px;
    padding: 5px 15px;
}


.pop-close-button {
    font-size: 13px;
    padding: 5px 15px;
    text-align: center;
    align-content: center;
}

.popup-logo {
    float: right;
    width: 153px;
    height: 44px;
}

.datePicker {
    /*background-color: black;*/
}

.get-rates {
    width: 109px;
    height: 44px;
    margin-top: -3px;
    /*outline: none;*/
    /*cursor: pointer;*/
    border: none;
    /*background: none;*/
}

.subscription-per-month {
    font-size: 12px;
    font-weight: normal;
    margin-left: 110px;
    margin-top: -18px;
    /*padding-top: 15px;*/
    display: flex;
    flex-direction: column;
}

.silver-top {
    width: 100%;
}

.copilottop {
    width: 100%;
}

button {
    border: none;
    background: none;
}

.get-rates-col {
    /*width: 100px;*/
    /*height: 40px;*/
}

.farelanes-background-image {

    /*background: url('assets/images/farelanes-splash.jpg') no-repeat center center fixed;*/
    /*-webkit-background-size: cover;*/
    /*-moz-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-size: cover;*/

    /*background-image: url('assets/images/farelanes-splash.jpg');*/
    background-size: cover;
    /*height: 100%;*/
}

.AppFonts {
    font-family: Overpass-SemiBold;
    font-size: 11px;
}

.AppFonts_Tickers {
    font-family: Overpass-Medium;
    font-size: 15px;
}

.load-message {
    color: greenyellow;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.top-routes-text {
    font-family: Overpass-Regular;
    font-size: 16px;
}

.top-routes-dollar-text {
    font-family: Overpass-SemiBold;
    font-size: 16px;

}

.samll-date {
    font-size: 11px;
}

.button-home {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.coupon-code {
    /*margin-top: 9px;*/
    /*padding-bottom: 20px;*/
}
.how-did-you-hear {
    font-size: 16px;
}
.wow {
    width: 300px;
    height: 500px;
}
.fcuk {
    width: 300px;
    height: 500px;
}

.pricing-item {
    padding: 0 20px;
}

.farelanes-button {
    font-family: Overpass-SemiBold;
    background-color: #028E7D;
    color: white;
    font-weight: bold;
}

.ticker {
    font-family: Overpass-Regular;
    font-size: 25px;
}

.rdt_TableHeader {
    font-size: 20px;
}

body {
    /*font-family: Overpass-Medium;*/
    padding-bottom: 70px;
    font-family: Overpass-Medium;
}

.farelanes-background-color {
    background-color: #028E7D;
    height: 100px;
}


.farelanes-nav-bar {
    height: 110px;
}

.farelanes-header-font-color {
    /*background-image: url("assets/images/farelanes-logo.png");*/
    /*margin-left: -270px;*/
    width: 350px;
    height: 100px;
    font-family: "Overpass-Light";
}

.farelanes-header-font-color-silver {
    /*background-image: url("assets/images/farelanes-logo-silver.png");*/
    /*margin-left: -270px;*/
    width: 350px;
    height: 100px;
    font-family: "Overpass-Light";
}


.farelanes-header-font-color-gold {
    /*background-image: url("assets/images/farelanes-logo-gold.png");*/
    /*margin-left: -270px;*/
    width: 350px;
    height: 100px;
    font-family: "Overpass-Light";
}


.header-font {
    color: #f7f7f7;
    font-family: "Overpass-Medium";
    font-size: 18px;
}

.navbar-light .navbar-nav .nav-link {
    color: #f7f7f7;
    font-size: 14px;
    text-transform: uppercase;
    font-family: Overpass-Regular;
}

.nav-item {
    margin-right: 66px;
}

.navbar .router-link-exact-active {
    color: #fdb614;
    border-bottom: 3px solid #fdb614
}

.nav-link:hover {
    color: #fdb614;
}

.farelanes-header-logo {
    margin-left: -16px;
    /*width: 115px;*/
    /*height: 40px;*/
    /*display: block;*/
}

a:hover {
    color: #fdb614;
}

/* selected link */
a:active {
    color: #fdb614;
}

.read-external-article {
    color: orange;
    font-size: 17px;
    text-decoration: underline;
}

.faq-text {
    color: black;
    font-size: 15px;
}
.faq-text-mobile {
    color: black;
    text-decoration: underline;
}

.faq-text:hover {
    color: black;
}


label {
    font-size: 12px;
    display: block;
    margin-top: 10px;
}

.card-container.card {
    /*max-width: 350px !important;*/
    /*margin-right: 20px;*/
    /*padding: 40px 40px;*/
}

.card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
}

.lookup-card {
    border: none;
    margin: 5px;
    /*border-radius: 1px;*/
    /*-moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);*/
    /*-webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);*/
    /*box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);*/
}

.card {
    /*padding: 5px;*/
    /*border-radius: 1px;*/
    /*-moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);*/
    /*-webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);*/
    /*box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);*/
}

.profile-img-card {
    /*width: 96px;*/
    /*height: 96px;*/
    margin: 0 auto 10px;
    display: block;
    /*-moz-border-radius: 50%;*/
    /*-webkit-border-radius: 50%;*/
    /*border-radius: 50%;*/
}

.cool-back {
    font-size: 15px;
}

.lookup-map {
    align-content: center;
    text-align: center;
    margin-left: 70px;
}

.header-logo {
    width: 115px;
    height: 40px;
    display: block;
    margin-top: 5px;
    /*-moz-border-radius: 50%;*/
    /*-webkit-border-radius: 50%;*/
    /*border-radius: 50%;*/
}

.tooltip-image {
    width: 26px;
    height: 26px;
    /*margin: 0 auto 2px;*/
    /*display: block;*/
    /*-moz-border-radius: 50%;*/
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.the-legend {
    border-style: none;
    border-width: 0;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    width: auto;
    padding: 0 10px;
    color: #0b2e13;
}

.the-fieldset {
    border: 1px solid #e0e0e0;
    padding: 4px;
    background-color: black;
    font-size: 25px;
}

.the-fieldset-ticker-one {
    width: 100%;
    padding: 10px;
    border: 3px solid #e0e0e0;
}


.the-fieldset-lane-price {
    /*margin-top: 10px;*/
    /*margin-left: -22px;*/
    /*margin-right: -22px;*/
    /*width: 100%;*/
    /*padding: 10px;*/
    /*border: 3px solid #e0e0e0;*/
}

.the-fieldset-ticker-two {
    /*border: 1px solid #e0e0e0;*/
    /*color: #e4606d;*/
}

.fixed {
    /*width: 350px;*/
    /*padding: 5px;*/
    /*margin: 5px;*/
}

.card-width {
    width: 500px;
}

.empty-background {
    background-color: white;
}

.special-card {
    /* create a custom class so you
       do not run into specificity issues
       against bootstraps styles
       which tends to work better than using !important
       (future you will thank you later)*/
    /*background-color: rgba(245, 245, 245, 1);*/
    opacity: .2;
}

.dashboard-header {
    font-family: Overpass-SemiBold;
    font-weight: bold;
    font-size: 14px;
}

.dashboard-table {
    /*max-width: 400px;*/
    /*height: 360px;*/
    /*margin: 10px;*/
    overflow-x: initial;
    text-align: justify;
}




/*footer {*/
/*    background: magenta;*/
/*    padding: 10px;*/
/*    height: 50px;*/

/*}*/

.footer-border {
}

.weather-text {
    font-size: 12px;
}

.heart-button {
    font-size: 18px;
    /*width: 10px;*/
    /*margin-left: 40px;*/
}

.i-button {
    font-size: 22px;
    /*width: 10px;*/
    /*margin-left: 40px;*/
}

.footer-color {
    background-color: #fdb614;
    height: 30px;
    text-align: center;
}

.footer-style {
    padding: 5px;
    margin-top: 10px;
}
.footer-style-mobile {
    padding: 5px;
    font-family: Overpass-Black'';
    font-weight: bold;
    margin-top: 15px;
}

.footer-normal-text {
    font-weight: normal;
}

.faq {
    color: black;
}

.footer-text {
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
}
.footer-right {
    position: relative;
    float: right;
}

.footer-center {
    align-items: center;
    align-content: center;
    text-align: center;
}

.farelanes-footer a {
    color: black;
}

.the-footer {
    color: #5a6268;
    font-size: 15px;
}


.city-width {
    width: 400px;
}

.login-input {
    width: 400px;
    margin-left: 300px;
    color: white;
    font-family: Overpass-SemiBold;
    font-size: 25px;
}

.login-input-text {
    width: 400px;
    margin-left: 300px;
}


.lead {
    font-size: small;
    font-weight: bold;
}

.the-fieldset-profile {
    border: 1px dodgerblue;
    font-size: smaller;
    padding: 4px;
    /*background-color: #f7f7f7;*/
}


/*Tickers*/

.pricing-content {
    position: relative;
}

.pricing_design {
    /*position: relative;*/
    /*margin: 10px 15px;*/
}

.pricing_design .single-pricing {
    height: 100%;
    /*padding: 30px 30px;*/
    border-radius: 130px;
    position: relative;
    z-index: 1;
}

.mobile-home {
    margin-top: 40px;
    margin-bottom: 10px;
}

.pricing_design .single-pricing:before {
    content: "";
    background-color: #fff;
    width: 100%;
    height: 100%;
    border: 3px solid ghostwhite;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.a01 {
    margin-top: 20px;
}

.a02 {
    margin-top: 33px;
}

.foo-bar {
    color: red;
    background: black;
}

.price-head {
}

.price-head h2 {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 600;
}

.price-head h1 {
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 5px;
}

.price-head span {
}

.single-pricing ul {
    list-style: none;
    margin-top: 30px;
}

.single-pricing ul li {
    line-height: 36px;
}


.subscription-history-margin {
    margin-top: 100px;
}

.subscription-details-font {

    font-size: 13px;
}

.single-pricing ul li i {
    background: #028E7D;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
    margin-right: 6px;
}

.pricing-price {
}


.fhr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #028E7D;
    margin: 1em 0;
    padding: 0;
}

.bg-image {
    /*background-image: url('assets/images/denver.jpeg');*/
    /*height: 100vh*/
    /*background-image: url("assets/images/denver.jpeg");*/
}

.price_btn {
    background: #028E7D;
    padding: 10px 30px;
    color: #fff;
    display: inline-block;
    margin-top: 20px;
    border-radius: 2px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.price_btn:hover {
    background: #0aa1d6;
}

a {
    text-decoration: none;
}

.section-title {
    margin-top: 40px;
}



.save-button-pairs {
    margin-left: 20px;
}

.section-title h2 {
    font-size: 45px;
    font-weight: 600;
    margin-top: 0;
    position: relative;
    text-transform: capitalize;
}

.admin-button {
    margin-top: 20px;
}

.static {
    width: 100%;
}

.vl {
    border-left: 1px outset #d3d3d3;
    height: 400px;
}

.disabled-link {
    pointer-events: none;
}

.forgot-password-box {
    margin-top: 33px;
    margin-left: 10px;
}

.green {
    color: #028E7D;
    font-size: 16px;
}


.next-steps .fa-link {
    margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
    margin-bottom: 0;
}

.next-steps .col-md-5 {
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    .next-steps .col-md-5 {
        margin-bottom: 0;
    }
}

.spinner {
    color: #028E7D;
    /*position: absolute;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*height: 100vh;*/
    /*width: 100vw;*/
    /*background-color: white;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
}

.result-block-container .result-block {
    opacity: 1;
}

.btn-primary {
    color: #0b2e13;
    background-color: #04AA6D;
    border: 1px solid #e0e0e0;
}

.btn {
    border-radius: .1rem
}


.intro {
    /*align-content: flex-start;*/
    /*text-align: left;*/
    /*margin-left: -470px;*/
    /*height: 100%;*/
}

.gradient-custom {
    /* fallback for old browsers */
    /*background: #fa709a;*/

    /* Chrome 10-25, Safari 5.1-6 */
    /*background: -webkit-linear-gradient(to bottom right, rgba(250, 112, 154, 1), rgba(254, 225, 64, 1));*/

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /*background: linear-gradient(to bottom right, rgba(250, 112, 154, 1), rgba(254, 225, 64, 1))*/
}

.profile-pairs {
    /*background: ghostwhite;*/
    padding: 20px;
    border: 2px solid ghostwhite;
    border-radius: 2px;
}

.profile-tickerOne {
    border: ghostwhite 2px double;
    padding: 20px;
}

.profile-tickerTwo {
    border: ghostwhite 2px double;
    padding: 20px;
}

.spinner-farelanes {
    text-align: center
}

.profile-root-tabs {
    font-size: 17px;
    text-align: left;
    font-family: Overpass-SemiBold;
    background: ghostwhite;
}



.profile-child-tabs {
    font-size: 15px;
    text-align: left;
}
.home-image {
    margin-left: -70px;
}

.admin-sync-subit {
    padding: 10px;
    font-size: 20px;
}

.admin-sync-subit-spiiner {
    margin-bottom: 5px;
}

.rates-chart {
    color: #fdb614;
    border: 1px solid ghostwhite;
}

.red-font {
    color: red;
}

.rates_border {
    /*border: 1px solid ghostwhite;*/
}

.rdt_Table {
    direction: ltr;
}

.rdt_TableCell, .rdt_TableCol {
    direction: ltr
}


.gbutton {
    background-color: #04AA6D; /* Green */
    border: whitesmoke 3px solid;
    color: white;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}


.image_wrapper {
    position: relative;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
}


.subscriptions-coming-soon {
    background-color: rgba(245, 245, 245, 1);
    opacity: .2;
    /*display: inline-block;*/
    text-shadow: 0 0 2px #fff;
    margin-top: 100px;
    font-size: 35px;
    text-transform: capitalize;
    font-weight: bold;
    color: green;
    transform: rotate(-38deg);
    transform-origin: left;
    margin-left: 31px;
    margin-bottom: 0px;
}

.left-align-text {
    font-size: 12px;
    text-align: left;
    font-family: Overpass-SemiBold;
    margin-left: 10px;
}

.react-tabs__tab--disabled {
    color: #aaaaaa;
}


.lef-align-line-height {
    line-height: 5px;
}

.left-align-text-after {
    text-align: left;
    font-size: 11px;
    line-height: 92%;
    /*line-height: 5px;*/
    /*font-weight: bold;*/
    margin-left: 22px;
    margin-bottom: 7px;
}

.align-farelanes-content-center {
    line-height: 120%;
    font-size: 13px;
    text-align: center;
    padding: 3px;
    font-weight: bold;
    align-items: center;
}

.two-months-free {
    margin-top: 8px;
    text-align: center;
    font-size: 11px;
    line-height: 120%;
    font-weight: bold;
    line-height: 1px;
    /*line-height: 5px;*/
    /*font-weight: bold;*/
}

.text-underline {
    tex-decoration: underline;
}

.top-no-browser {
    /*margin-top: 10px;*/
}

.secondary-tabs-align {
    margin-left: 40px;
}

.tab-days {
    font-weight: bold;
    font-size: 20px;
}

.widget-wrap {
    margin-top: -30px;
    text-align: center;
}


.g_maps {
    width: 100%;
    height: 222px;
}

.username-field {
    font-size: 11px
}

.pclass {
    width: 200px;
    /*font-family: Overpass-Bold;*/
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: whitesmoke;
    border: 1px solid #a0a096;
    font-size: 12px;
    font-family: Overpass-Bold;
    line-height: 1.125em;
}

.lane-data-button {
    margin-top: 38px;
}


.lane-data-a-button {
    margin-top: 18px;
    width: 100px;
}

a:hover {
    text-decoration: none;
}

.rates-search-price-chart {

    /*background: #f7f7f7;*/
    /*border: 1px solid green;*/
}

.rates-search-price-border {
    /*background: #f7f7f7;*/
    /*border: 1px solid green;*/
}

.rates-search-params-border {
    /*background: #f7f7f7;*/
    /*border: 1px solid green;*/
}

.price-component-tool-tip {
}

.search_label {
    color: #1bc99f;
}

.bb {
    /*align-content: center;*/
    margin-left: 50px;
}

/*iframe#webpack-dev-server-client-overlay{display:none!important}*/


.file-download-text {
    color: black;
    font-size: 14px;
    font-family: Overpass-Light;
    padding: 4px;
}

.kapil {
    margin: 10px;
    /*margin-left: 12px;*/
    /*width: 100%;*/
    /*margin: 100px;*/
    /*margin-top: 50px;*/
}

.new-ticker-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
}

.new-ticker {
    font-family: FLTicker-Regular;
    font-size: 24px;
    /*font-weight: bold;*/
    background: #0d3316;
    color: #51ff00;
    padding: 2px;
    margin: -2px;
}

.new-ticker-mobile {
    font-family: FLTicker-Regular;
    font-size: 12px;
    /*font-weight: bold;*/
    background: #0d3316;
    color: #51ff00;
    padding: 4px;
    margin: -2px;
}

.marquee-fuel-ticker {
    /*width: 600px;*/
}

.font-test {
    font-family: FLTicker-Regular;
}
.fuel-ticker {
    font-family: FLTicker-Regular;
    font-size: 20px;
    /*font-weight: bold;*/
    background: #0d3316;
    color: #51ff00;
    padding: 2px;
    margin: -2px;
    /*text-align: left;*/
}

.fuel-main-div {
    margin: auto;
    width: 50%;
}


.new-ticker-overlay {
    background: url('images/green-neon.png');
    repeat: repeat-x;
}

.new-ticker-container {
    width: 100%;
}

.inline-fl {
    display: inline;
    padding-bottom: 20px;
    margin-bottom: 25px;
}
.toggle-adjust {
    /*margin-top: 100px;*/
/*padding-top: 20px;*/
}

.ticker-layout {
    display: flex;
    justify-content: right;
    /*padding: 1.2rem;*/
    background-color: var(--black);
    /*width: 100%;*/
    /*max-width: 1200px;*/
    /*flex: 1;*/
    /*display: flex;*/
    /*align-items: flex-end;*/
    /*justify-content: flex-end;*/
}

.v2-ticker-legend {
    color: yellow;
    margin-bottom: -1px;
    font-size: 7px;
}

.v2-brand-logo {
    width: 250px;
    height: 75px;
    text-align: left;
}

.v2-container {
    width: 100%;
    background-color: #000000;;
}

.avengers-tab-panel {
    background-color: #028e7d;;

}
.popup-highlight-city {
    font-family: Overpass-Bold;
    font-size: 14px;
}
.popup-dashboard-vertical-divider {
    border-right: 1px double lightgrey;
    height: 600px;
}

.v2-dashboard-vertical-divider {
    border-right: 1px double #333;
    height: 800px;
}


.how-do-you-know-divider {
    border-right: 1px double #333;
    height: 500px;
}

.pos-right {
    /*padding-left: 700px;*/
    float: right;
    margin-left: 500px;
}

.v2-profile-circle li {
    all: unset;
    /*margin-right: 1px;*/
}

.nav-link {
    display: block;
    padding-top: 20px;;
    padding-left: 88px;
}

.v2-btn-login {
    margin-top: 21px;
    /*float: right;*/
}

.v2-tab-content {
    font-size: 11px;
}

.width500 {
    width: 1500px;
}


.right-float {
    float:right;
}

.add_lane_dashboard_button {
    background-color: #fcb900 !important; /* GREEN */
    width: 85px;
    margin-bottom: 4px;
    /*padding-top: 5px;*/
    /*padding-bottom: 5px;*/
    /*paqdding-left: 10px;*/
    /*padding-right: 10px;*/
    border: none;
    color: black;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 2px solid black;
    /*outline-radius: 10px;*/
    /*outline-offset: -6px;*/
    text-transform: uppercase;
}

.add_lane_dashboard_button-mobile {
    background-color: #fcb900 !important; /* GREEN */
    align-content: center;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    border: none;
    color: black;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    outline: 2px solid black;
    text-transform: uppercase;
}
ul {
    list-style-type: none;
}

.menu-item {
    background: ghostwhite;
    font-size: 9px;
    width: 18px;
    /*padding-right: -22px;*/
    /*padding: 16px;*/
}


.rd-text {font-size: 14px;
}

.dashboard-dollar-prices {
    font-size: 17px;
    /*font-family: Overpass-SemiBold;*/
    /*font-weight: bold;*/
}

.react-confirm-alert-body > h1 {
    font-size: 14px;
    color: red;
    font-weight: bold;
}

.react-confirm-alert-body {
    font-size: 14px;
    /*color: red;*/
}

.black-heart {
    /*color: black;*/
}

.empty-maps-div {
        width: 400px;
        height: 200px;
        border: 2px black solid;
}

.react-tabs__tab--selected {
    /*background-color: #028e7d;;*/
    /*color: #ffffff;*/
    /*font-size: 13px;*/
}

.react-tabs__tab--selected {

/*color: green;*/

}

.small_zips {
    font-family: Overpass-ExtraLight;
    font-size: 8px;
}

.green-bg {
    font-family: "Overpass-Bold";
    text-align: center;
    background-color:#028e7d;
    color: #fff;
    padding: 8px;
    font-size: 17px;
}

.green-color-text {
    color:#028e7d;
    font-weight: bold;
}


.big-trash {
    font-size: 17px;
}

.gray-bg {
    text-align: center;
    font-weight: bold;
    background-color: #ffffff;
    border: 1px solid #a0a096;
    /*color: #fff;*/
    padding: 10px;
    font-size: 15px;
}
iframe#webpack-dev-server-client-overlay{display:none!important}

.scrollable-div {
    /*background-color: #f1f1f1;*/
    height: 360px;
    overflow: auto;
    /*margin: 10px;*/
    text-align: justify;
    /*padding: 20px;*/
}

.toast-message {
    color: #fff;
    text-align: center;
    font-size: 17px;
    width: 24vw;
    /*padding: 30px 20px;*/
}

.spacer-top-20 {
    margin-top: 30px;
}

.spacer-top-15 {
    margin-top: 9px;
}

.right-dashboard-social-buttons {
}

.dashboard-text {
    font-size: 14px;
}

.one-space-low {
    margin-bottom: 3px;
}

.one-space-low-mobile {
    margin-bottom: 0.06em;


}

.fl-mob-logo {
    height: 66px;
    /*background-color: #0b2e13;*/
    width: 289px;
}

.sidebar-mobile {
    font-family: Overpass-ExtraLight;
    font-size: 14px;
    padding-bottom: 10px;
    color: #0d3316;
}


.m2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.m2 span {
    margin-top: 10px;
    background:#fff;
    padding:0 10px;
    font-size: 12px;
}

header{
    margin-top: 10px;
    border:1px blanchedalmond solid;
    /*height:300px;*/
    position:relative;
}

header:before{
    position:absolute;
    top:-10px;
    left:50px;
    background:#fff;
    padding:0 20px;
}